.jagdfieber {
	display: inline-block;

	svg {
		fill: currentColor;
		max-width: 25px;
		height: 25px;
	}
}

.jagdfieber-herz {
	
	svg {
		max-width: 100px;
		max-height: 95px;
	}
}