.entry-header {
	overflow: hidden;
}

.byline,
.updated:not( .published ){
	display: none;
}

.single .byline,
.group-blog .byline {
	display: inline;
}

.page-content,
.single .entry-content,
.entry-summary {
	margin: 1.5em 0 0;
}

.static {
	position: static;
}

.row {

	&::before,
	&::after {
		width: 0;
	}
}
