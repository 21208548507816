@font-face {
	font-family: 'metro';
	src: url( '../fonts/regular/metro-regular-webfont.woff' ) format( 'woff' );
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'metro';
	src: url( '../fonts/semibold/metro-semibold-webfont.woff' ) format( 'woff' );
	font-weight: bold;
	font-style: normal;
}