.site-header {
	background-color: $darkgrey;
	padding: 45px 15px;
	width: 100px;
	height: 100vh;
	position: fixed;
	transition: 0.5s all ease;


	@media (max-width: $screen-xs-max) {
			height: auto;
			width: 100vw;
			padding: 10px;
			z-index: 99;
		}

	button {
		margin-left: 1em;
		background-color: #cccccc;
	}

	.static {
		padding: 0;
	}


	li {
		padding-top: 10px;
		margin-bottom: 10px;
		background-color: transparent;


		&.menu-item--active,
		&.menu-item-current {
			border-bottom: 2px solid $white;
			display: inline-block;
		}

		a {
			color: $lightergrey;
			font-size: 1.25em;
			padding-left: 15px;
			display: block;

		}
	}

	.footer-navigation {
		margin-top: 4em;

		@media (max-width: $screen-xs-max) {
			margin-top: 1em;
		}

		a {
			color: $lightgrey;


		}
	}

	.content-overflow {
		padding: 0;

		@media (max-width: $screen-xs-max) {
			background-color: $darkgrey;
		}
	}

	.site-branding {
			a {
				display: block;
				text-align: left;
				margin-left: 15px;

				@media (max-width: $screen-xs-max) {
					text-align: right;
					margin-left: 0;
					margin-right: 1em;
				}


				&:hover {
					opacity: 1;
				}

				img {
					width: 75px;

					@media (max-width: $screen-xs-max) {
						height: 60px;
					}
				}
			}
	}

	.off-canvas-wrapper {
		@media (min-width: $screen-sm-min) {
			margin-top: 2em;
		}
	}

	.off-canvas {
		@media (min-width: $screen-sm-min) {
			position: relative;
			transform: translateX(-124%);
			width: 100%;
		}

		.off-canvas__toggle {
			position: relative !important;
			color: $lightgrey;
			top: auto;
			margin-left: 1.5em;
			background-color: transparent;

			@media (max-width: $screen-xs-max) {
				margin-left: 0;
				margin: 0.25em,
			}


			&--close {
				display: none;
			}

			&--open {
				display: block;
			}

			svg {
				height: 30px;
				fill: $lightgrey;
				width: 50px;
			}

			&:hover {
				color: $white;

				svg {
					fill: $white;
				}
			}
		}
	}
}

.menu-open {

	.site-header {
		width: 13vw;

		@media (max-width: $screen-md-max) {
			width: 20vw;
		}

		@media (max-width: $screen-sm-max) {
			width: 22vw;
		}

		@media (max-width: $screen-xs-max) {
			width: 100vw;
		}


		.off-canvas {
			transform: translateX(0);

			.off-canvas__toggle {
				left: 0;
				margin-left: 0;

				&--open {
					display: none;
				}

				&--close {
					display: block;
				}
			}
		}
	}

	.site-content {

		.row {
			padding-left: 11vw;

			@media (max-width: $screen-xs-max) {
				padding-left: 0;
			}

		}
	}
}