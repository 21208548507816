p,
.p,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
th
{
	font-family: $font-family-base;
	color: $darkgrey;
	font-weight: normal;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 
{
	font-weight: bold;
}

p {
	font-size: $font-size-base;
	line-height: 1.85em;
}

a {
	color: inherit;
	font-weight: bold;
	transition: 0.5s all ease;
	text-decoration: none;

	&:hover {
		color: inherit;
		text-decoration: none;
		opacity: 0.5;
	}
}

h1,
.h1 {
	font-size: 3em;

	@media (max-width: $screen-xs-max) {
		font-size: 2.5em;
	}
}

h2,
.h2 {
	font-size: 2em;
}

sub {
	font-size: 1.25em;
	top: 0em;
	margin-bottom: 1.5em;
	bottom: 0;
	display: inline-block;
	line-height: 1em;
}



/*** Hintergrundbilder ***/

#einleitung {
	background-image: url(/assets/img/lewkowitz-kanzlei-1.jpg);
	background-position: left;

	@media (max-width: $screen-sm-min) {
		background-image: url(/assets/img/lewkowitz-kanzlei-1-768.jpg);
	}
}

#anwalt {
	background-image: url(/assets/img/lewkowitz-kanzlei-2.jpg);

	@media (max-width: $screen-sm-min) {
		background-image: url(/assets/img/lewkowitz-kanzlei-2-768.jpg);
	}
}


#leistung {
	background-image: url(/assets/img/lewkowitz-kanzlei-3.jpg);

	@media (max-width: $screen-md-max) {
		background-image: url(/assets/img/lewkowitz-kanzlei-3-1550.jpg);
	}

	@media (max-width: $screen-sm-min) {
		background-image: url(/assets/img/lewkowitz-kanzlei-3-768.jpg);
	}
}

#buero {
	background-image: url(/assets/img/lewkowitz-kanzlei-4.jpg);

	@media (max-width: $screen-md-max) {
		background-image: url(/assets/img/lewkowitz-kanzlei-4-1550.jpg);
	}

	@media (max-width: $screen-sm-min) {
		background-image: url(/assets/img/lewkowitz-kanzlei-4-768.jpg);
	}
}

.bg-img {
	background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    background-attachment: fixed;

	@media (max-width: $screen-xs-max) {
		background-image: none !important;
		background-color: $white;
	}
}

.black {
	background-color: $black;
	padding: 5% 6%;

	.white {
		h1,
		.h1,
		h2,
		.h2,
		h3,
		.h3,
		p,
		.p,
		a {
			@media (max-width: $screen-xs-max) {
				color: $white;
			}
		}
	}
}


/*** Leistungen/Icons ***/

.leistungsgebiete {
	background-color: $black;

	.leistungsgebiete-block {

		svg {
			fill: $darkgrey;
			display: block;
			margin: auto;
		}

		h2 {
			text-align: center;
			margin-bottom: 2em;

			@media ( max-width: $screen-xs-max ) {
				color: $white;
			}
		}

		.leistung {
			padding: 2em 2em 1em;
			z-index: 99;

			@media ( max-width: $screen-xs-max ) {
				width: 100%;
				margin-left: 15px;
				margin-right: 15px;
				text-align: center;
			}


			svg {
				width: 100px;
				height: 124px;
			}

			h3 {
				text-align: center;
			}

			&.arbeitsrecht {
				background-color: $grey1;
			}

			&.familienrecht {
				background-color: $grey2;
			}

			&.reiserecht {
				background-color: $grey3;
			}

			&.verkehrsrecht {
				background-color: $grey4;
			}

			&.inkassosachen {
				background-color: $grey5;
			}

			&.weitere {
				background-color: $grey6;
			}
		}
	}

}

.dn {
	@media (max-width: $screen-xs-max) {
		display: none;
	}	
}


@media (min-width: $screen-sm-min) {
	.white {
		color: $white;

		p,
		.p,
		h1,
		.h1,
		h2,
		.h2,
		h3,
		.h3,
		h4,
		.h4,
		sub {
			color: $white;
		}
	}
}

.top {
	@media (min-width: $screen-sm-min) {
		margin-top: 30%;
	}
}


.top-half {
	@media (min-width: $screen-sm-min) {
		margin-top: 15%;
	}
}

.site-content {


	.full {
		width: 100%;
		min-height: 100vh;

		@media (max-width: $screen-xs-max) {
			min-height: auto;
			width: 100%;
		}
	}

	.text {
		padding: 5% 6%;
		transition: all ease 1.5s;
		opacity: 0;

		&.fade-in {
			transform: translate(0)!important;
			opacity: 1;
		}

		@media (max-width: $screen-xs-max) {
			padding: 30px;
		}
	}

	#einleitung-text,
	#leistungsgebiete-text {
		transform: translateY(100px);

		@media (max-width: $screen-xs-max) {
			transform: translateY(50px);
		}
	}

	#leistung-text,
	#anwalt-text,
	#buero-text {
		transform: translateX(-4000px);

		@media (max-width: $screen-xs-max) {
			transform: translateX(-500px);
		}

	}

	#lebenslauf-text {
		transform: translateX(4000px);

		@media (max-width: $screen-xs-max) {
			transform: translateX(500px);
		}
	}

	.om,
	.formobile {
		width: 100%;

		p {
			color: $white;
		}

		@media (min-width: $screen-sm-min) {
			display: none !important;
		}	
	} 


}

.right {
	float: right;

	@media (max-width: $screen-xs-max) {
		float: none;
	}	
}



.pattern {
	background-image: url(/assets/img/pattern.png);

	.text-center {
		margin: auto;
		text-align: center;
		display: block;
		width: 100%;
		font-size: 1.5em;

		p {
			font-size: 1.5em;
			line-height: 1.5em;
			color: $lightgrey;
			margin-top: 1em;
		}

		span {
			font-weight: bold;
			font-size: 2.5em;
			margin-top: 0.25em;
			margin-bottom: 0.25em;
			display: block;
			color: #dedede;
			text-decoration: none;

			@media (min-width: $screen-md-min) {
				font-size: 2em;
			}
		}

		@media (min-width: $screen-sm-min) {
			margin-top: 4em;
		}

		@media (max-width: $screen-sm-max) {
			span {
				font-size: 1.25em;
			}

			p {
				font-size: 1em;
			}
		}
	}
}

table {

	tr {
		margin-bottom: 1em;
		display: -webkit-box;

		@media (max-width: $screen-xs-max) {
			display: block;
		}

		th {
			text-align: left;
			vertical-align: top;
			display: block;

			&:nth-child(1) {
				width: 25%;
				font-weight: bold;
				margin-right: 2em;

				@media (max-width: $screen-xs-max) {
					width: 100%;
				}
			}
		}		
	}
}

#kontakt {

	#kontakt-text {
		display: block;
		margin: auto;
		padding-left: 45px;
		padding-right: 45px;
	}

	p,
	h2,
	sub,
	.btn {
		color: $white;
	}

	label {
		margin-bottom: 1.5em;

		span {
			font-size: 0.85em;
		}

		input,
		textarea {
			background-color: #3e3e3e;
			border: none;
			font-size: 1em;
		}
	}

	.formular {
		.btn {
			text-align: right;
			display: block;
			width: 100%;
			margin-top: 3em;
			font-size: 1.25em;
			font-style: italic;
			transition: 0.5s all ease;

			&:hover {
				text-decoration: none;
				color: $lightgrey;
			}

			@media(max-width: $screen-xs-max) {
				text-align: center;
			}
		}

		.send-success {
			color: #7edc68;
		}

		.send-error {
			color: #ff5656;
		}
	}
}


.site-content {
	@media (min-width: $screen-xs-max) {
		padding-left: 100px;
	}

	@media (max-width: $screen-xs-max) {
		padding-top: 80px;
	}
}

@media (max-width: $screen-xs-max) {
	.row {
		margin-left: -30px;
		margin-right: -30px;
	}
}
