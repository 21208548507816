$darkgrey: #333333;
$lightergrey: #ededed;
$lightgrey: #9d9c9c;
$grey: #aaaaaa;
$black: #000000;
$white: #ffffff;

$grey1: #ededed;
$grey2: #e3e3e3;
$grey3: #dadada;
$grey4: #d0d0d0;
$grey5: #c6c6c6;
$grey6: #a5a5a5;

$font-family-base: 'metro';
$font-size-base: 18px;